<template>
  <div>
    <x-select-periodo-fiscal @input="onChangePeriodoFiscal" class="mb-3" modulo="Partidas" />

    <div v-if="isNotBusy">
      <b-table :fields="fields" :items="items" bordered stacked="sm">
        <template #cell(estatus)="row">
          <b-badge :variant="$getStatusVariant(row.item.estatus)">
            {{ $getStatusText(row.item.estatus) }}
          </b-badge>
        </template>

        <template #cell(acciones)="row">
          <u-d-controls
            :id="row.item.id"
            editPath="partidaModule/getToEdit"
            deletePath="partidaModule/delete"
            :editPermissions="[permissions.update]"
            :deletePermissions="[permissions.delete]"
            @on-got-item="$up"
            @on-deleted="emitOnInput"
          ></u-d-controls>
        </template>
      </b-table>
    </div>
    <loading v-else class="py-10"></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PartidaList',

  props: {
    permissions: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      periodo_fiscal_id: null,

      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'periodo_fiscal', label: 'Periodo fiscal' },
        { key: 'estatus', label: 'Estatus', sortable: true },
        { key: 'acciones', label: 'Acciones' }
      ]
    }
  },

  computed: {
    ...mapGetters('partidaModule', { items: 'getPartidas', isNotBusy: 'getAvailableList' })
  },

  methods: {
    emitOnInput () {
      this.$emit('input', this.periodo_fiscal_id)
    },

    async onChangePeriodoFiscal (id) {
      this.periodo_fiscal_id = id

      this.emitOnInput()
    }
  }
}
</script>
