<template>
  <base-view icon="dollar-sign" title="Partidas">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <!-- Formulario partidas -->
    <partida-form
      v-show="showForm || isEditingResource"
      @hide-form="onHideForm"
      @submitted="getListadoPartidas"
    />

    <!-- Listado partidas -->
    <check-authorization :requiresAuthorizations="[permissions.list]">
      <div class="card card-header-actions">
        <div class="card-header">
          Partidas
          <x-btn-with-auth
            v-show="!showForm"
            :requiresAuthorizations="['crear partidas']"
            :callback="cancel"
            variant="success"
            btnClass="ml-auto"
          >
            {{ !showForm ? 'Crear' : 'Ocultar' }}
          </x-btn-with-auth>
        </div>
        <div class="card-body p-3">
          <partida-list :permissions="permissions" @input="onChangePeriodoFiscal" />
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import PartidaForm from '@/components/Catalogos/Partidas/PartidaForm'
import PartidaList from '@/components/Catalogos/Partidas/PartidaList'

export default {
  name: 'PartidasView',

  components: {
    PartidaForm,
    PartidaList
  },

  created () {
    this.permissions = this.$router.currentRoute.meta.permissions
  },

  data () {
    return {
      showForm: false,

      //
      // Periodo fiscal que se ha seleccionado para filtrar las partidas
      periodo_fiscal_id: null,

      permissions: {}
    }
  },

  computed: {
    ...mapGetters('partidaModule', ['isEditingResource'])
  },

  methods: {
    ...mapMutations('partidaModule', ['setCurrentPartida']),

    ...mapActions('partidaModule', ['getAll']),

    async getListadoPartidas () {
      const { error, message } = await this.getAll(this.periodo_fiscal_id)

      if (error) this.$notify({ error, message }, 'Listado de proyectos')
    },

    cancel () {
      this.showForm = !this.showForm
    },

    onChangePeriodoFiscal (periodo_fiscal_id) {
      this.periodo_fiscal_id = periodo_fiscal_id

      this.getListadoPartidas()
    },

    onHideForm () {
      this.showForm = false
    }
  }
}
</script>
